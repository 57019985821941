import request from '@/utils/requestV2'

// 获取线路的品牌、行业的销售排行
export function countContractAmountByIndustryOrBrand (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/countContractAmountByIndustryOrBrand',
    method: 'get',
    params: data
  })
}
// 获取线路的上刊率、销售额贡献率、商业成熟率的雷达图
export function getAssetBusinessRadar (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/getAssetBusinessRadar',
    method: 'get',
    params: data
  })
}
// 获取线路的权益额(40%刊例,60%客流均摊)
export function getAssetExecuteAmount (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/getAssetExecuteAmount',
    method: 'get',
    params: data
  })
}
// 查询线路的媒体类型
export function listAssetMediaType (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listAssetMediaType',
    method: 'get',
    params: data
  })
}
// 查询产品的上刊率、成交率和预售率
export function listAssetProductLineChart (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listAssetProductLineChart',
    method: 'get',
    params: data
  })
}
// 查询线路下的产品
export function listAssetProducts (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listAssetProducts',
    method: 'get',
    params: data
  })
}
// 获取每条线路的权益额(40%刊例,60%客流均摊)
export function listExecuteAmountGbAsset (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listExecuteAmountGbAsset',
    method: 'get',
    params: data
  })
}
// 查询产品的上刊率、成交率和预售率
export function listProductLineChart (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listProductLineChart',
    method: 'get',
    params: data
  })
}
// 查询时间段售卖出的产品列表
export function listProductsBySales (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listProductsBySales',
    method: 'get',
    params: data
  })
}
// 查询线路下的产品
export function listProducts (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listProducts',
    method: 'get',
    params: data
  })
}
// 查询设备层的线路列表
export function listRimAssets (data) {
  return request({
    url: '/ooh-dw/v1/datav/asset/listRimAssets',
    method: 'get',
    params: data
  })
}

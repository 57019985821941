<style>
  .ivu-table td.demo-table-info-column-operating{
    background-color: #515a6e !important;
  }
</style>

<template>
  <div>
    <Row class="m-b-10" :gutter="16">
      <i-col :xs="12" :sm="12" :md="6" :lg="4">
        <Select size="small" v-model="selectAssetId">
          <Option v-for="item in assetList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col :xs="12" :sm="12" :md="6" :lg="3">
        <DatePicker v-model="searchDate" type="month" size="small" :clearable="false" style="width: 100%;"></DatePicker>
      </i-col>
      <i-col :xs="12" :sm="12" :md="6" :lg="4">
        <i-button size="small" type="primary" icon='ios-search' @click="handleSearch">查询</i-button>
        <i-button size="small" type="success" icon="ios-download-outline" class="m-l-5" @click="handleExportResult">导出查询结果</i-button>
      </i-col>
    </Row>

    <Table stripe :data="datalist" :columns="tableColumns" size="small"></Table>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { listRimAssets } from '@/api/dw/asset'
import { listMonthFee } from '@/api/bill/fee'

export default {
  data () {
    return {
      assetList: [],
      selectAssetId: null,
      searchDate: null,

      datalist: [],
      tableColumns: [
        { title: '站点名称', key: 'stationName' },
        { title: '站点级别', key: 'stationLevel' },
        {
          title: '灯箱媒体',
          align: 'center',
          children: [
            { title: '招标数量', key: 'boxNumber', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '年经营权费单价', key: 'boxUnitAmount', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '实际数量', key: 'boxActualNumber', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '月经营权费', key: 'boxActualAmount', align: 'center', className: 'demo-table-info-column-operating' }
          ]
        },
        {
          title: '梯牌媒体',
          align: 'center',
          children: [
            { title: '招标数量', key: 'ladderNumber', align: 'center' },
            { title: '年经营权费单价', key: 'ladderUnitAmount', align: 'center' },
            { title: '实际数量', key: 'ladderActualNumber', align: 'center' },
            { title: '月经营权费', key: 'ladderActualAmount', align: 'center' }
          ]
        },
        {
          title: '数字媒体',
          align: 'center',
          children: [
            { title: '招标数量', key: 'digitalNumber', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '年经营权费单价', key: 'digitalUnitAmount', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '实际数量', key: 'digitalActualNumber', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '月经营权费', key: 'digitalActualAmount', align: 'center', className: 'demo-table-info-column-operating' }
          ]
        },
        {
          title: '非常规广告',
          align: 'center',
          children: [
            { title: '年经营权费', key: 'unconventionalYearAmount', align: 'center' },
            { title: '月经营权费', key: 'unconventionalActualAmount', align: 'center' }
          ]
        },
        {
          title: '语音播报',
          align: 'center',
          children: [
            { title: '年经营权费', key: 'voiceYearAmount', align: 'center', className: 'demo-table-info-column-operating' },
            { title: '月经营权费', key: 'voiceActualAmount', align: 'center', className: 'demo-table-info-column-operating' }
          ]
        },
        {
          title: '列车媒体',
          align: 'center',
          children: [
            { title: '年经营权费', key: 'tranYearAmount', align: 'center' },
            { title: '月经营权费', key: 'tranActualAmount', align: 'center' }
          ]
        }
      ]
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = {
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      listRimAssets(query).then(res => {
        this.assetList = res

        if (this.assetList.length > 0) {
          this.selectAssetId = this.assetList[0].id
          this.searchDate = new Date()

          this.handleSearch()
        }
      })
    },
    handleSearch () {
      const query = {
        assetId: this.selectAssetId,
        feeMonth: ParseDate(this.searchDate).substring(0, 7)
      }

      listMonthFee(query).then(res => {
        this.datalist = res
      })
    },
    handleExportResult () {
      const query = {
        assetId: this.selectAssetId,
        feeMonth: ParseDate(this.searchDate).substring(0, 7)
      }

      const downloadFileName = this.assetList.find(x => x.id === this.selectAssetId).name +
         query.feeMonth +
         '经营权费.xlsx'

      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-bill/right/fee/exportMonthFee',
        query, downloadFileName)
    }
  }
}
</script>
